import {Injectable} from '@angular/core';
import {Observable, retry, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthService} from '../modulos/core/services/auth.service';
import {ErroHttp} from './erro-http';
import {LocalStorageService} from '../modulos/core/services/localstorage-service';
import {Rotas} from '../consts/rotas';

@Injectable({
    providedIn: 'root'
})
export class SisBaseHttpInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router, private _storageService: LocalStorageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let accessToken = '';
        const userObj = this._storageService.getData(environment.nomeUsuarioLocalStorage);
        if (userObj) {
            accessToken = userObj.token;
        }

        // Adiciona o JWT em cada requisição que é realizada
        // const user = this.authService.userValue;
        // const isLoggedIn = (user && user.token);
        // const jwtToken = (isLoggedIn ? user.token : '');
        // const jwtToken = localStorage.getItem(environment.nomeColabTokenLocalStorage);
        const isLoggedIn = (accessToken && accessToken != '');
        //const isApiUrl = request.url.startsWith(environment.apiUrl);
        // Se for request para arquivo json estático (idioma por exemplo) não joga o JWT
        const ehJson = request.url.endsWith('.json');

        if (isLoggedIn /*&& isApiUrl*/ && !ehJson) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,PUT,PATCH,DELETE',
                    'Access-Control-Allow-Headers': 'token, Origin, X-Requested-With, Content-Type, Accept, Authorization',
                    'Access-Control-Allow-Credentials': 'true',
                }
            });
        }

        // Manipula o request
        return next.handle(request)
            .pipe(
                retry(1), // Em caso de erro do request, vai tentar novamente por X vezes
                catchError((err: any) => {
                    // Se der erro 401 ou 403, verifica se precisa deslogar o usuário da aplicação
                    if ([401, 403].includes(err.status) && this.authService.userValue) {
                        if (err.status == 403) {

                            // Faz o redirect para a página de forbidden
                            this.router.navigate([Rotas.FORBIDDEN], {
                                state: {
                                    status: err.status,
                                    url: err.url,
                                    erroOriginal: err.error,
                                    mensagem: ErroHttp.getErroDaMensagem(err),
                                    rota: this.router.url,
                                }
                            });

                        } else {
                            err.message = 'CORE.SESSAO_EXPIRADA';

                            this.authService.efetuarLogoff();
                            if (!environment.production) {
                                console.error(err);
                            }
                        }
                    }

                    if (err.status == 504) {
                        err.message = 'CORE.ERRO_COMUNICACAO_API';
                    }

                    if (!environment.production) {
                        console.error(err);
                    }

                    // Se for outros erros, gera a exception
                    return throwError(() => new ErroHttp({
                        httpCode: err.status,
                        mensagem: ErroHttp.getErroDaMensagem(err),
                        erroOriginal: err
                    }));
                }));
    }
}
