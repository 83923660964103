import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {MainHttpService} from './main-http.service';
import {firstValueFrom} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {AgendaSearchModel} from '../models/agenda-search.model';
import {AgendaModel} from '../models/agenda.model';

@Injectable({providedIn: 'root'})
export class AgendaService extends MainHttpService {

    getListAutoComplete(params: AgendaSearchModel) {
        params.qdePorPagina = environment.qdeRegistrosAutocomplete;
        return firstValueFrom(this.getLista(params));
    }

    getLista(params: AgendaSearchModel): Observable<any> {
        const searchParams = `?pagina=${params.paginaAtual}&qde_por_pagina=${params.qdePorPagina}&titulo=${params.titulo}&descricao=${params.descricao}&data_inicial=${params.dataInicial}&data_final=${params.dataFinal}&order_field=${params.campoOrdenacao}&order_direction=${params.ordemOrdenacao}`;
        const path = '/agenda/list-all' + searchParams;
        return super.get(path);
    }

    override get(params: any): Observable<any> {
        const path = '/agenda/' + params;
        return super.get(path);
    }

    save(params: any): Observable<any> {
        let path = '/agenda';
        if (params.id > 0) {
            path = path + '/' + params.id;
            return super.put(path, params);
        } else {
            return super.post(path, params);
        }
    }

    override delete(id: any): Observable<any> {
        const path = '/agenda/' + id;
        return super.delete(path);
    }


    getListaHoje() {
        const path = '/agenda/hoje';
        return super.get(path);
    }

    finalizarEvento(agendaModel: AgendaModel) {
        let path = '/agenda/' + agendaModel.id + '/finalizar';
        return super.put(path, agendaModel);
    }
}
