import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {MainHttpService} from './main-http.service';
import {firstValueFrom} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {NotificacaoSearchModel} from '../models/notificacao-search.model';
import {NotificacaoModel} from '../models/notificacao.model';

@Injectable({providedIn: 'root'})
export class NotificacaoService extends MainHttpService {

    getListAutoComplete(params: NotificacaoSearchModel) {
        params.qdePorPagina = environment.qdeRegistrosAutocomplete;
        return firstValueFrom(this.getLista(params));
    }

    getLista(params: NotificacaoSearchModel): Observable<any> {
        const searchParams = `?pagina=${params.paginaAtual}&qde_por_pagina=${params.qdePorPagina}&titulo=${params.titulo}&mensagem=${params.mensagem}&order_field=${params.campoOrdenacao}&order_direction=${params.ordemOrdenacao}`;
        const path = '/notificacoes/list-all' + searchParams;
        return super.get(path);
    }

    override get(params: any): Observable<any> {
        const path = '/notificacoes/' + params;
        return super.get(path);
    }

    save(params: any): Observable<any> {
        let path = '/notificacoes';
        if (params.id > 0) {
            path = path + '/' + params.id;
            return super.put(path, params);
        } else {
            return super.post(path, params);
        }
    }

    override delete(id: any): Observable<any> {
        const path = '/notificacoes/' + id;
        return super.delete(path);
    }

    marcarComoLida(id: any): Observable<any> {
        const path = '/notificacoes/' + id + '/lida';
        return super.post(path, {});
    }

    getQdeNaoLidas() {
        const path = '/notificacoes/count-nao-lida';
        return super.get(path);
    }

    deleteEmBatch(lista: NotificacaoModel[]): Observable<any> {
        const payload = {
            ids: lista.map(n => n.id)
        };
        const path = '/notificacoes/delete-user';
        return super.post(path, payload);
    }

    deleteAll(): Observable<any> {
        const path = '/notificacoes/delete-all-user';
        return super.delete(path);
    }
}
