import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppTopbarService {

    private notificacaoSource = new Subject<any>();
    notificacaoSource$ = this.notificacaoSource.asObservable();

    private agendaSource = new Subject<any>();
    agendaSource$ = this.agendaSource.asObservable();

    onLerNotificacao() {
        this.notificacaoSource.next(null);
    }

    onLerEventoAgenda() {
        this.agendaSource.next(null);
    }

}
